import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFileEcontract } from "../services/apiService";
import LoadingOverlay from "../components/Loading";

const Econtract = () => {
  const navigate = useNavigate();
  const [isAccepted, setIsAccepted] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const finalId = id !== null ? id : userId;
  const [loadings, setLoadings] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(false);
  const [isSafari, setIsSafari] = useState(false);
  useEffect(() => {
    const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent
    );
    setIsSafari(isSafariBrowser);
  }, []);
  useEffect(() => {
    const fetchPdf = async () => {
      setLoadings(true);
      try {
        const response = await getFileEcontract(finalId);
        if (response.data && response.data.file_url) {
          setPdfUrl(response.data.file_url);
        } else {
          console.error("Không tìm thấy file_url trong phản hồi API.");
        }
      } catch (error) {
        console.error("Lỗi khi lấy file PDF:", error.message);
      } finally {
        setLoadings(false);
      }
    };

    fetchPdf();
  }, []);
  const handleIframeLoad = () => {
    setIframeLoading(false);
  };

  const handleCheckboxChange = (e) => {
    setIsAccepted(e.target.checked);
  };
  useEffect(() => {
    if (pdfUrl) {
      setIframeLoading(true);
    }
  }, [pdfUrl]);

  return (
    <div className="flex flex-col h-screen w-full bg-white">
      {(loadings || iframeLoading) && <LoadingOverlay />}{" "}
      <h1 className="uppercase text-xl font-bold text-green-500 text-center mb-4">
        Hợp đồng điện tử
      </h1>
      <div className="flex-grow h-full w-full">
        {loadings ? (
          <LoadingOverlay />
        ) : (
          pdfUrl && (
            <iframe
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                pdfUrl
              )}&embedded=true`}
              width="100%"
              height="100%"
              style={{ border: "none" }}
              title="E-Contract PDF"
              onLoad={handleIframeLoad}
            />
          )
        )}
      </div>
      <div className={`${
          isSafari ? "sticky bottom-16" : "sticky bottom-6"
        } transform`}>
        <div className="flex items-center justify-between p-4 bg-gray-100">
          <label className="flex items-center space-x-2 ml-5">
            <input
              type="checkbox"
              checked={isAccepted}
              onChange={handleCheckboxChange}
              className="h-4 w-4"
            />
            <span className="text-gray-700 text-xs">
              Chấp nhận với các điều khoản (*)
            </span>
          </label>
          <button
            onClick={() => {
              if (isAccepted) {
                navigate("/vpbank-creditcard/OTPScreencf");
              }
            }}
            className={`px-4 py-2 w-32 rounded text-white mr-4 ${
              isAccepted
                ? "bg-green-500 hover:bg-green-600"
                : "bg-gray-400 cursor-not-allowed"
            }`}
            disabled={!isAccepted}
          >
            Xác nhận
          </button>
        </div>
      </div>
    </div>
  );
};

export default Econtract;
