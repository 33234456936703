import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sadface from "../assets/anxiety.png";
import { useUserContext } from "../UserContext";
import { getLead } from "../services/apiService";

const CancelCreditCard = () => {
  const navigate = useNavigate();
  const [leadStatus, setLeadStatus] = useState(null);
  const [appData, setAppData] = useState({ app_id: null, request_id: null }); 
  const [kycSegment, setKycSegment] = useState(null); 
  const id = localStorage.getItem("id");
  useEffect(() => {
    if (id) {
      const interval = setInterval(() => {
        getLead(id)
          .then((response) => {
            const { app_id, request_id, status, kyc_segment } = response.data.data;
            if (kyc_segment === "NTB" || kyc_segment === "ETB_NONE_VERIFIED") {
              setKycSegment(kyc_segment);
              localStorage.setItem("kyc_segment", kyc_segment);
            }
            setLeadStatus(status);
            setAppData((prev) => {
              if (prev.app_id !== app_id || prev.request_id !== request_id) {
                return { app_id, request_id };
              }
              return prev;
            });
            if (status === "Processing") {
              navigate("/vpbank-creditcard/chooseProduct");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 4000);

      return () => clearInterval(interval);
    }
  }, [id, navigate]);
  return (
    <div className="flex flex-col items-center p-5 bg-white h-screen">
      <h1 className="uppercase text-xl font-bold mt-6 text-green-500 text-center">
        Hồ sơ của bạn không được phê duyệt từ ngân hàng
      </h1>
      <div className="bg-white h-24 mt-3">
        <img className=" mt-5 w-36 " src={sadface} alt="sadface" />
      </div>
      <div className="mt-[95px] uppercase text-sm font-bold text-center">
        Rất tiếc hồ sơ của bạn chưa được phê duyệt, hãy quay lại sau nhé!
      </div>

    </div>
  );
};

export default CancelCreditCard;
