import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../UserContext";
import { fetchCities } from "../services/locationService";
import LoadingOverlay from "../components/Loading";
import Swal from "sweetalert2";
const GuideVKYC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center p-4 bg-white">
      <div className="w-full text-left mt-2 ml-10">
        <p className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-800 to-green-600">
          Hướng dẫn VKYC
        </p>
        <p className="text-[12px] font-light">Sau đây, bạn sẽ được kết nối Tổng đài Video call.</p>
      </div>
      <div className="top-8 p-3 mt-8 bg-green-300 transform rounded-[15px] w-full max-w-md custom-shadow">
        <div className="text-xs font-bold ml-3 mt-2">Quy trình gọi video gồm 3 bước:</div>
      <div className="flex items-center gap-4 px-4 mt-3 ml-2">
          <img
            id="frontImage"
            src={require("../assets/Frame.png")}
            alt="image"
            className="w-[9%]"
          />
          <p className="text-xs">Chờ kết nối tổng đài viên</p>
        </div>
      <div className="flex items-center gap-4 px-4 mt-2 ml-2">
          <img
            id="frontImage"
            src={require("../assets/Frame (1).png")}
            alt="image"
            className="w-[9%]"
          />
          <p className="text-xs">Xác thực khuôn mặt & CMND/CCCD</p>
        </div>
      <div className="flex items-center gap-4 px-4 mt-2 ml-2 mb-3">
          <img
            id="frontImage"
            src={require("../assets/Frame (2).png")}
            alt="image"
            className="w-[9%]"
          />
          <p className="text-xs">Đọc & Ký kết hợp đồng điện tử</p>
        </div>
      </div>


      <div className="top-8 p-3 mt-8 bg-gray-200 transform rounded-[15px] w-full max-w-md custom-shadow">
        <div className="text-xs font-bold ml-3 mt-2">Hướng dẫn nhỏ để bạn chuẩn bị sẵn cho cuộc gọi video</div>
      <div className="flex items-center gap-4 px-4 mt-3 ml-2">
          <img
            id="frontImage"
            src={require("../assets/front.png")}
            alt="image"
            className="w-[9%]"
          />
          <p className="text-xs">Chuẩn bị CMND/CCCD</p>
        </div>
      <div className="flex items-center gap-4 px-4 mt-3 ml-2">
          <img
            id="frontImage"
            src={require("../assets/Frame (3).png")}
            alt="image"
            className="w-[7%]"
          />
          <p className="text-xs ml-1">Thời gian gọi 5 - 8 phút</p>
        </div>
      <div className="flex items-center gap-4 px-4 mt-3 ml-2 mb-3">
          <img
            id="frontImage"
            src={require("../assets/Frame (4).png")}
            alt="image"
            className="w-[7%]"
          />
          <p className="text-xs ml-1">Không gian yên tĩnh, có kết nối mạng tốt</p>
        </div>
      </div>
      <div className="items-center justify-center mt-10 mb-10">
        <button       onClick={() => navigate("/vpbank-creditcard/waiting")}           className="bg-gradient-to-r from-green-700 to-green-500 rounded-[10px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"        >

          <p className="text-white text-base font-semibold">Tôi đã sẵn sàng</p>
        </button>
      </div>
    </div>
  );
};

export default GuideVKYC;
