import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../UserContext";
import { fetchCities } from "../services/locationService";
import LoadingOverlay from "../components/Loading1";
import Swal from "sweetalert2";
import { getLead } from "../services/apiService";
const Waiting = () => {
  const navigate = useNavigate();
  const [loadings, setLoadings] = useState(true);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");

  const finalId = id !== null ? id : userId;

  useEffect(() => {
    if (finalId) {
      const interval = setInterval(() => {
        getLead(finalId)
          .then((response) => {
            const { vpo_status } = response.data.data;

            if (vpo_status === "Yes") {
              clearInterval(interval);
              navigate("/vpbank-creditcard/complete");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }, 5000); 

      return () => clearInterval(interval);
    }
  }, [finalId, navigate]);
  return (
    <div className="flex flex-col items-center p-4 bg-white mb-[130px]">
      <div className="w-full text-left mt-2 ml-10">
        <p className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-green-800 to-green-600">
          Đợi kết quả
        </p>
        <p className="text-[12px] font-light">
          Kết quả sẽ có sau khi bạn thực hiện Video call
        </p>
      </div>
      <div className="top-8 p-3 mt-8 bg-green-300 transform rounded-[15px] text-center items-center w-full max-w-md custom-shadow">
        <div className="text-xs font-bold ml-3 mt-6 text-[#15562d]">
          Hồ sơ của bạn đang được phê duyệt
        </div>
        <div className="text-xs  ml-3 mt-2 text-[#15562d]">
          Vui lòng chờ trong giây lát
        </div>
        <div className="flex justify-center mt-4 mb-[30px]">
          <img
            id="frontImage"
            src={require("../assets/Group 67.png")}
            alt="image"
            className="w-[18%]"
          />
        </div>
        {loadings && <LoadingOverlay />}
      </div>
    
      {/* <div className="items-center justify-center mt-10 mb-10">
        <button       onClick={() => navigate("/vpbank-creditcard/waiting")} className="bg-green-500 rounded-lg w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500 ">
          <p className="text-white text-base font-semibold">Tôi đã sẵn sàng</p>
        </button>
      </div> */}
    </div>
  );
};

export default Waiting;
