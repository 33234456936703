import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderRegister from "../components/HeaderReigister";
import {
  checkPhoneExists,
  getDataPhone,
  getGender,
  getMaritalStatus,
  Starting_info,
} from "../services/apiService";
import { useUserContext } from "../UserContext";
import LoadingOverlay from "../components/Loading";
import "../output.css";
import Swal from "sweetalert2";

const Step1Screen = () => {
  const navigate = useNavigate();
  const [currentStep] = useState(1);
  const [selectedGender, setSelectedGender] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [maritalStatuss, setMaritalStatuss] = useState([]);
  const [genders, setGenders] = useState([]);
  const { userInfo, setUserInfo } = useUserContext();
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState({
    fullName: false,
    phoneNumber: false,
    email: false,
    dob: false,
    gender: false,
    maritalStatus: false,
  });
  const [phoneExists, setPhoneExists] = useState(null);
  const validatePhoneNumber = (phone) => {
    const validPrefixes = [
      "032",
      "033",
      "034",
      "035",
      "036",
      "037",
      "038",
      "039",
      "052",
      "055",
      "056",
      "058",
      "059",
      "070",
      "076",
      "077",
      "078",
      "079",
      "081",
      "082",
      "083",
      "084",
      "085",
      "086",
      "087",
      "088",
      "089",
      "09",
    ];
    return validPrefixes.some((prefix) => phone.startsWith(prefix));
  };

  const handleFormSubmit = async () => {
    let valid = true;
    const newErrors = {
      fullName: !userInfo.full_name,
      phoneNumber:
        !userInfo.phone_number || !validatePhoneNumber(userInfo.phone_number),
      email: !userInfo.email || !validateEmail(userInfo.email),
      dob: !userInfo.dob,
      gender: !selectedGender,
      maritalStatus: !selectedStatus,
    };
    if (userInfo.dob) {
      const dobDate = new Date(userInfo.dob);
      const birthYear = dobDate.getFullYear();
      const age = new Date().getFullYear() - dobDate.getFullYear();
      if (age < 18) {
        newErrors.dobUnderage = true;
        valid = false;
      }
      if (birthYear <= 1995) {
        localStorage.setItem("isBornBefore1995", true);
      } else {
        localStorage.setItem("isBornBefore1995", false);
      }
    }
    if (phoneExists) {
      newErrors.phoneNumber = true;
      valid = false;
    }
    for (const key in newErrors) {
      if (newErrors[key]) valid = false;
    }

    const response = await checkPhoneNumberExists({
      phone_number: userInfo.phone_number,
    });
    if (
      response?.data?.message ===
        "Phone number already exists in the partner system." &&
      response?.data?.status === false
    ) {
      newErrors.phoneNumber = true;
      valid = false;
    }

    setErrors(newErrors);

    if (valid) {
      const dataToSubmit = {
        ...userInfo,
        gender: selectedGender,
        marital_status: selectedStatus,
      };

      setUserInfo(dataToSubmit);
      try {
        const response = await Starting_info(dataToSubmit);
        const newUserId = response.data.data.id;

        localStorage.setItem("id", newUserId);

        navigate("/vpbank-creditcard/OTPScreen");
      } catch (error) {
        console.error("Error creating user:", error);
      }
    }
  };
  const checkPhoneNumberExists = async (phone_number) => {
    setLoadings(true);
    try {
      const response = await checkPhoneExists(phone_number);
      if (
        response.data.message ===
          "Phone number already exists in the partner system." &&
        response.data.status === false
      ) {
        setPhoneExists(true);

        Swal.fire({
          title: "Cập nhật hồ sơ",
          text: "Còn vài bước nữa sẽ hoàn tất hồ sơ, bạn có muốn tiếp tục?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Có",
          cancelButtonText: "Không",
        }).then(async (result) => {
          if (result.isConfirmed) {
            const internalSteps = await getDataPhone(phone_number.phone_number);
            const userId = internalSteps.data.data.id;
            const kyc_step = internalSteps.data.data.kyc_segment;
            const dob = internalSteps.data.data.dob;
            const phoneStep = internalSteps.data.data.phone_number;
            const yearOfBirth = new Date(dob).getFullYear();
            localStorage.removeItem("id");
            localStorage.setItem("userId", userId);
            localStorage.setItem("kyc_step", kyc_step);
            localStorage.setItem("yearOfBirth", yearOfBirth);
            localStorage.setItem("phoneStep", phoneStep);
            switch (internalSteps.data.data.internal_steps) {
              case "STARTING_INFO":
                navigate("/vpbank-creditcard/step2Screen");
                break;
              case "BASIC_INFO":
                navigate("/vpbank-creditcard/step3Screen");
                break;
              case "RESIDENCE_INFO":
                navigate("/vpbank-creditcard/step4Screen");
                break;
              case "JOB_INFO":
                navigate("/vpbank-creditcard/chooseProduct");
                break;
              case "EKYC_ID":
                navigate("/vpbank-creditcard/face");
                break;
              case "EKYC_LIVENESS":
                navigate("/vpbank-creditcard/contact");
                break;
              case "REFERENCE_INFO":
                if (kyc_step != "ETB_VERIFIED") {
                  navigate("/vpbank-creditcard/guidevkyc");
                } else {
                  navigate("/vpbank-creditcard/econtract");
                }
                break;
              case "EKYC_FRONT":
                navigate("/vpbank-creditcard/ocr-back");
                break;
              case "EKYC_BACK":
              case "EKYC_QR":
                if (kyc_step != "ETB_VERIFIED") {
                  navigate("/vpbank-creditcard/contact");
                } else {
                  navigate("/vpbank-creditcard/face");
                }
                break;
              default:
                break;
            }            
          }
        });
      } else {
        setPhoneExists(null);
      }
    } catch (error) {
      console.error("Error checking phone number:", error);
      setPhoneExists(null);
    } finally {
      setLoadings(false);
    }
  };
  useEffect(() => {
    setLoadings(true);
    getMaritalStatus()
      .then((response) => {
        setMaritalStatuss(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);
  useEffect(() => {
    setLoadings(true);
    getGender()
      .then((response) => {
        setGenders(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);

  const validateName = (name) => {
    return name.replace(/[!@#$%^&*`~&*]/g, "");
  };
  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  return (
    <div className="flex flex-col items-center p-4 bg-white ">
      {loadings && <LoadingOverlay />}

      <HeaderRegister currentStep={currentStep} />
      <div className="mt-8 w-full max-w-md bg-[#52df8f] rounded-2xl p-4 custom-shadow">
        <div className="text-center mb-4">
          <p className="text-xl font-bold uppercase">Thông tin cơ bản</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Họ và tên:
          </label>
          <input
            className={`h-10 rounded-md px-2 text-sm bg-white w-full ${
              errors.fullName ? "border border-red-500" : ""
            }`}
            placeholder="Nhập họ và tên"
            type="text"
            value={userInfo.full_name}
            onChange={(e) =>
              setUserInfo({
                ...userInfo,
                full_name: validateName(e.target.value),
              })
            }
          />
          {errors.fullName && (
            <p className="text-red-500 text-xs">
              Họ và tên không được để trống.
            </p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Số điện thoại:
          </label>
          <input
            className={`h-10 rounded-md text-sm px-2 bg-white w-full ${
              errors.phoneNumber ? "border border-red-500" : ""
            }`}
            placeholder="Nhập số điện thoại"
            type="tel"
            pattern="[0-9]*"
            inputMode="numeric"
            maxLength={10}
            value={userInfo.phone_number}
            onChange={(e) => {
              const input = e.target.value.replace(/[^0-9+]/g, "");
              const isValid = input.length === 10;
              setUserInfo({
                ...userInfo,
                phone_number: input,
              });
              setErrors((prevErrors) => ({
                ...prevErrors,
                phoneNumber: false,
                phoneNumber: !isValid,
              }));
            }}
          />
          {errors.phoneNumber && (
            <p className="text-red-500 text-xs">
              Số điện thoại phải đúng định dạng hoặc đã tồn tại.
            </p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Email:
          </label>
          <input
            className={`h-10 rounded-md px-2 text-sm bg-white w-full ${
              errors.email ? "border border-red-500" : ""
            }`}
            placeholder="Nhập email"
            type="text"
            value={userInfo.email}
            onChange={(e) => {
              setUserInfo({
                ...userInfo,
                email: e.target.value,
              });
              setErrors((prevErrors) => ({
                ...prevErrors,
                email: !validateEmail(e.target.value),
              }));
            }}
          />
          {errors.email && (
            <p className="text-red-500 text-xs">Email không hợp lệ.</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Ngày, Tháng, Năm sinh:
          </label>
          <input
            className={`h-10 rounded-md text-sm px-2 bg-white w-full ${
              errors.dob || errors.dobUnderage ? "border border-red-500" : ""
            }`}
            placeholder="Nhập ngày tháng năm sinh"
            type="date"
            value={userInfo.dob}
            onChange={(e) => setUserInfo({ ...userInfo, dob: e.target.value })}
          />

          {errors.dob && (
            <p className="text-red-500 text-xs">
              Ngày sinh không được để trống.
            </p>
          )}
          {errors.dobUnderage && (
            <p className="text-red-500 text-xs">
              Bạn phải trên 18 tuổi để đăng ký.
            </p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Giới tính:
          </label>
          <div className="flex bg-white rounded-lg p-2">
            {genders.map((gender) => (
              <div className="flex items-center mb-2" key={gender.code}>
                <input
                  type="checkbox"
                  id={`gender-${gender.code}`}
                  checked={selectedGender === gender.code}
                  onChange={() =>
                    setSelectedGender(
                      selectedGender === gender.code ? "" : gender.code
                    )
                  }
                  className="hidden"
                />
                <div
                  className="w-5 h-5 border-[0.7px] border-green-500 rounded-[6px] flex items-center justify-center cursor-pointer"
                  onClick={() =>
                    setSelectedGender(
                      selectedGender === gender.code ? "" : gender.code
                    )
                  }
                >
                  {selectedGender === gender.code && (
                    <span className="bg-green-400 rounded-[3px] w-[13px] h-[13px]" />
                  )}
                </div>
                <label
                  htmlFor={`gender-${gender.code}`}
                  className="cursor-pointer ml-1 p-2"
                >
                  {gender.name}
                </label>
              </div>
            ))}
          </div>
          {errors.gender && (
            <p className="text-red-500 text-xs">
              Giới tính không được để trống.
            </p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Tình trạng hôn nhân:
          </label>
          <div className="flex flex-col bg-white rounded-lg p-2">
            {maritalStatuss.map((status) => (
              <div className="flex items-center mb-2" key={status.id}>
                <input
                  type="checkbox"
                  id={`status-${status.id}`}
                  checked={selectedStatus === status.id}
                  onChange={() =>
                    setSelectedStatus(
                      selectedStatus === status.id ? null : status.id
                    )
                  }
                  className="hidden"
                />
                <div
                  className="w-5 h-5 border-[0.7px] border-green-500 rounded-[6px] flex items-center justify-center cursor-pointer"
                  onClick={() =>
                    setSelectedStatus(
                      selectedStatus === status.id ? null : status.id
                    )
                  }
                >
                  {selectedStatus === status.id && (
                    <span className="bg-green-400 rounded-[3px] w-[13px] h-[13px]" />
                  )}
                </div>
                <label
                  htmlFor={`status-${status.id}`}
                  className="cursor-pointer ml-2"
                >
                  {status.name}
                </label>
              </div>
            ))}
          </div>
          {errors.maritalStatus && (
            <p className="text-red-500 text-xs">
              Tình trạng hôn nhân không được để trống.
            </p>
          )}
        </div>
      </div>
      <div className="mt-6 mb-10">
        <button
          onClick={handleFormSubmit}
          className="bg-gradient-to-r from-green-700 to-green-500 rounded-[17px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"        >
          <p className="text-white text-base font-semibold">Tiếp tục</p>
        </button>
      </div>
    </div>
  );
};

export default Step1Screen;
