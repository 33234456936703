import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import _, { get } from "lodash";
import { useUserContext } from "../../UserContext";
import Swal from "sweetalert2";
import {
  uploadEKYCQr,
  uploadOCRQR,
} from "../../services/apiService";
import LoadingOverlay from "../../components/Loading";

const VerifyInfoBack = () => {
  const navigate = useNavigate();
  const { userInfo, setUserInfo } = useUserContext();
  const { productInfo, setProductInfo } = useUserContext();
  const [listObjUrls, setListObjUrls] = useState([]);
  const [isImageCaptured, setIsImageCaptured] = useState(false);
  const lang = localStorage.getItem("lang");
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState({});
  const frontImageRef = useRef(null);
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const backImageRef = useRef(null);
  const kyc_segment = localStorage.getItem("kyc_segment");
  const kyc_step = localStorage.getItem("kyc_step");

  function convertBlobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  const myCdnUrlPrefix = "https://vision-vnetwork-cdn.goevo.vn/";
  const customUrls = {
    blazefaceModelUrl: `${myCdnUrlPrefix}models/1.0.0/blazeface/model.json`,
    opencvUrl: `${myCdnUrlPrefix}opencv/4.6.0/cv.js`,
    idCardModelUrl: `${myCdnUrlPrefix}models/1.0.0/id_card/tflite/model-v3.tflite`,
    wechatQRModelsUrl: `${myCdnUrlPrefix}models/1.0.0/webchat_qr/`,
    tfScriptUrls: {
      tfUrl: `${myCdnUrlPrefix}tfjs/3.20.0/tf.min.js`,
      tfBackendWasmUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-wasm.js`,
      tfBackendCpuUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-cpu.js`,
      tfBackendWebGlUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-webgl.js`,
      tfLiteUrl: `${myCdnUrlPrefix}tflite/0.0.1-alpha.8/tflite.min.js`,
      blazefaceScriptUrl: `${myCdnUrlPrefix}blazeface/0.0.7/bf.js`,
    },
  };
  const sdkVersion = "5.22.11";
  useEffect(() => {
    const tv = new window.TVWebSDK.SDK({
      container: document.getElementById("container"),
      lang: lang || "vi",
      enableAntiDebug: false,
      assetRoot: myCdnUrlPrefix + "tvweb-sdk/" + sdkVersion + "/assets",
      resourceRoot: myCdnUrlPrefix,
      customUrls: customUrls,
    });

    window.tv = tv;
    tv.runPreloadEKYCResources();

    const userIdEl = document.getElementById("user-id");
    userIdEl.value = JSON.parse(localStorage.getItem("user-id"));

    const selectModeEl = document.getElementById("select-mode");
    Object.keys(window.TVWebSDK.Constants.Mode).forEach((k) => {
      const option = document.createElement("option");
      option.appendChild(document.createTextNode(k));
      option.setAttribute("value", window.TVWebSDK.Constants.Mode[k]);
      selectModeEl.appendChild(option);
    });

    const idCardSettingsEl = document.getElementById("id-card-settings");
    const idCardStepsEl = document.getElementById("id-card-steps");
    const trackingConfigEl = document.getElementById("tracking-config");

    idCardSettingsEl.value = JSON.stringify(
      window.TVWebSDK.defaultClientSettings,
      null,
      2
    );
    idCardStepsEl.value = JSON.stringify(
      window.TVWebSDK.defaultReadIDCardSteps,
      null,
      2
    );
    trackingConfigEl.value = JSON.stringify(
      window.TVWebSDK.TrackingContext.defaultTrackingConfig,
      null,
      2
    );


    return () => {
      tv.destroyView();
    };
  }, [lang]);

  const handleSubmit = async (updateOcrQR) => {
    const newErrors = {};
    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setLoadings(true);

      try {
        const finalId = id !== null ? id : userId;
        const kycFinal = kyc_segment !== null ? kyc_segment : kyc_step;
        const dataStatus = {
            OCR_Image_QR: updateOcrQR.OCR_QR,
          };
        await uploadEKYCQr(finalId, dataStatus);
        const ocrQRResponse = await uploadOCRQR(finalId);
        switch (
          String(get(ocrQRResponse, "data.data.status")).toUpperCase()
        ) {
          case "SUCCESS": {
            if (kycFinal != "ETB_VERIFIED") {
              navigate("/vpbank-creditcard/contact");
            } else {
              navigate("/vpbank-creditcard/face");
            }
            return;
          }
          case "RETRY": {
            Swal.fire({
              icon: "warning",
              title: "Yêu cầu chụp lại QR!",
              text: get(ocrQRResponse, "data.data.message",""),
              showCancelButton: true,
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            });
            return;
          }
          case "CANCEL": {
            navigate("/vpbank-creditcard/reject");
            return;
          }
        }
      } catch (error) {
        console.error("Cập nhật thất bại", error);
        Swal.fire({
          icon: "error",
          title: "Lỗi",
          text: "",
        });
      } finally {
        setLoadings(false);
      }
    }
  };

  function createObjectURL(blob) {
    const objUrl = URL.createObjectURL(blob);
    setListObjUrls((prev) => [...prev, objUrl]);
    return objUrl;
  }

  function revokeObjectURL() {
    listObjUrls.forEach((item) => URL.revokeObjectURL(item));
    setListObjUrls([]);
  }

  function clearUserId() {
    window.localStorage.removeItem("user-id");
    const userIdEl = document.getElementById("user-id");
    userIdEl.value = "";
  }

  async function handleStepDoneIDCard({
    stepNumber,
    image,
    cardSide,
    cardType,
    qrScannedResult,
    recordedVideos,
    apiResult,
  }) {


    const resultExtractIdInfoEl = document.getElementById(
      "result-extract-id-info"
    );

    setIsImageCaptured(true);

    const imgEl = document.createElement("img");
    imgEl.width = "300";
    // imgEl.src = URL.createObjectURL(image.blob);
    resultExtractIdInfoEl.appendChild(imgEl);

    if (qrScannedResult) {
      const { result, image: qrImage } = qrScannedResult;

      if (qrImage && qrImage.blob) {
        const qrBase64Image = await convertBlobToBase64(qrImage.blob);
        const updateOcrQR = {
          OCR_QR: qrBase64Image.replace(/^data:image\/[a-zA-Z]+;base64,/, ""),
        };
        setProductInfo((prevProductInfo) => ({
          ...prevProductInfo,
          ...updateOcrQR
        }));

        const qrImgEl = document.createElement("img");
        qrImgEl.width = "200";
        qrImgEl.src = createObjectURL(qrImage.blob);
        resultExtractIdInfoEl.appendChild(qrImgEl);
        
        window.tv.destroyView();
        handleSubmit(updateOcrQR);
      }


    }

    if (apiResult) {
      resultExtractIdInfoEl.appendChild(
        document.createTextNode("API Result:\n")
      );
      const apiResultEl = document.createElement("textarea");
      apiResultEl.value = JSON.stringify(apiResult, null, 2);
      apiResultEl.cols = 55;
      apiResultEl.rows = 15;
      resultExtractIdInfoEl.appendChild(apiResultEl);
    }

  }
  async function handleDetectIdCardCommon(props) {
    const { cardType, image, cardSide } = props;
    const apiClient = new window.trustvisionSdk.default(
      window.inputAccessKey.value,
      window.inputSecretKey.value,
      window.inputApiUrl.value
    );
    const resultUpload = await apiClient.uploadImage({
      file: image.blob,
      label: `id_card.${cardType}.${cardSide}`,
    });
    const imageId = resultUpload.data.image_id;
    const resultDetect = await apiClient.httpClient.post(
      "/v1/detect_id_cards_sync",
      {
        card_type: cardType,
        image: {
          id: imageId,
        },
      }
    );
    return _.get(resultDetect, "data.image.cards.0");
  }
  
  const qrScanStep = {
    scannerType: window.TVWebSDK.ScannerType.QR_CODE,
    title: "Quét mã QR trên CCCD",
    titleIcon: "",
    enableConfirmPopup: false,
  };

  
  function startExtractIDInfoQR() {
    revokeObjectURL();
    const resultExtractIdInfoEl = document.getElementById(
      "result-extract-id-info"
    );
    resultExtractIdInfoEl.innerHTML = "";
    document.body.style.height = 0;
  
    const idCardProps = {
      onError: (e) => {
        window.tv.destroyView();
      },
      onClose: () => {
        window.tv.destroyView();
      },
      detectIdCard: handleDetectIdCardCommon,
      onStepDone: handleStepDoneIDCard,
      steps: [qrScanStep],
    };
    if (window.inputAccessKey.value) {
      window.tv.readIDCardWithApiCall({
        apiCredentials: {
          accessKey: window.inputAccessKey.value,
          secretKey: window.inputSecretKey.value,
          apiUrl: window.inputApiUrl.value,
        },
        ...idCardProps,
      });
    } else {
      window.tv.readIDCardUIOnly(idCardProps);
    }
  }
  
  


  return (
    <div
      id="content"
      className="verify-info flex flex-col items-center p-5 bg-white  "
    >
      {" "}
      {loadings && <LoadingOverlay />}
      <div id="container"></div>
      <h1 className="text-xl font-bold mt-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-green-600 to-green-500">
        Xác thực giấy tờ tuỳ thân
      </h1>
      <p className="text-xs font-normal mt-3 text-gray-600 text-center">
      Vui lòng chụp QR CMND/CCCD
      </p>
      <div style={{ display: "none" }}>
        <input id="user-id" type="text" placeholder="User ID" />
        <button onClick={clearUserId}>Clear User ID</button>
      </div>
      <div style={{ display: "none" }}>
        <label htmlFor="select-mode">Select Mode:</label>
        <select id="select-mode"></select>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3"></div>
      <div
        id="read-id-card-loading"
        style={{ margintop: "10px", display: "none" }}
      >
        Api checking
        <div class="dot-windmill"></div>
      </div>
      <div className="h-[270px] w-[96%] bg-gray-300 rounded-3xl mt-5 flex flex-col items-center custom-shadow custom-shadow">
        <img
          alt="Chụp QR"
          src={require("../../assets/scan.png")}
          className="w-[60%] h-[60%] mt-10"
        />
        <p className="text-sm mt-3 font-semibold text-slate-700">
          Chụp QR CCCD/CMND
        </p>
      </div>
      <div class="d-flex justify-content-center align-items-center mt-3">
        <pre id="result-extract-id-info2"></pre>
      </div>
      <div className="items-center justify-center mt-9 mb-14">
        <button
        
          onClick={startExtractIDInfoQR}
          className="bg-gradient-to-r from-green-700 to-green-500 rounded-[10px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"        >
        
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>
      <div id="result-extract-id-info" style={{ display: "none" }}></div>
      <div id="result-liveness-detection"></div>
      <div id="id-card-settings"></div>
      <div id="id-card-steps"></div>
      <div id="tracking-config"></div>
      <div id="read-id-card-loading"></div>
      <div id="liveness-loading" style={{ display: "none" }}></div>
    </div>
  );
};

export default VerifyInfoBack;
