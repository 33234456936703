import React from 'react';
import "./loading.css"
const Loading1 = () => {
  return (

<span class="loader"></span>

  )
};
export default Loading1;
