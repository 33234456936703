import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo/vpbank.svg";
import logofimi from "../assets/Logo FiMi 4.svg";
import zalologo from "../assets/logo/zalo.png";

const PageStart = () => {
  const navigate = useNavigate();

  return (
    <div className=" items-center p-4 bg-white h-[2490px]">
      <div className="bg-white h-24 flex items-center justify-between px-9 mb-1 pb-5">
        <img className=" w-36 h-28" src={logofimi} alt="logo" />
        <img className=" w-36 h-16 mr-4" src={logo} alt="logo" />
      </div>

      <div className="flex items-center justify-center flex-col">
        <div className="font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-yellow-500 text-xl">
          MỞ THẺ KHÔNG CẦN
        </div>
        <div className=" font-extrabold leading-[3rem] text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-yellow-500 text-4xl">
          BẢNG LƯƠNG
        </div>
      </div>

      <div className="relative flex items-center justify-center h-[330px] ">
      
        <div className="absolute top-[9%] w-full z-0">
          <img src={require("../assets/logo/mở thẻ.png")} alt="Image 3" />
        </div>
      </div>
      <div class="flex items-center  ">
        <div class=" items-center text-center justify-center ">
          <div class="font-extrabold text-sm leading-tight">
            MỞ THẺ <span class="text-yellow-500">ONLINE</span> CHỈ VỚI
            <br />
            <span class="text-yellow-500">CCCD GẮN CHIP</span>
          </div>
          <div class=" text-[11px]">
            Không cần giấy tờ chứng minh thu nhập, chỉ với một lần đăng ký
            <br /> trong 10 phút.
          </div>
        </div>
        <div className="ml-1 mt-6 mb-6">
          <img
            src={require("../assets/logo/mở thẻ online chỉ với CCCD.png")}
            alt="Image 4"
            className="w-[300px] h-auto mb-12  "
          />
        </div>
      </div>
      <div className="relative">
        <img
          src={require("../assets/Rectangle 52.png")}
          alt="Rectangle"
          className="w-full h-auto"
        />
        <div className="absolute top-6 left-0 p-3 flex items-center">
          <div>
            <img src={require("../assets/logo/hạn mức.png")} alt="Image 4" className="w-[200px] "/>
          </div>
          <div className="flex flex-col items-center text-center w-[65%] leading-tight">
            <div class="font-extrabold text-yellow-500 mb-1">
              HẠN MỨC <span class="text-white">THẺ LÊN ĐẾN</span> <br />
              100 TRIỆU
            </div>
            <div class="text-[11px] text-white font-light">
              Chọn thẻ tín dụng VPBank theo
              <br /> nhu cầu chi tiêu cá nhân.
            </div>
            <div class="font-extrabold text-yellow-500 text-center mt-2 mb-1 leading-tight">
              MIỄN PHÍ
              <br />
              <span class="text-white ">PHÍ THƯỜNG NIÊN</span>
            </div>

            <div class="text-[11px] text-white font-light ">
              Cho năm đầu tiên và ưu đãi với những năm tiếp theo từng loại thẻ
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex mt-8">
          <div>
            <img src={require("../assets/logo/hoàn tiền lên đến...png")} alt="Image 4"  className="w-[200px]"/>
          </div>
          <div class="flex flex-col items-center text-center w-[50%] mt-[55px] ">
            <div class="font-extrabold text-yellow-500 leading-tight ">
              HOÀN TIỀN <span class="text-black">CHI TIÊU LÊN ĐẾN</span>{" "}
              15%/THÁNG
            </div>
            <div class="text-xs text-black font-light">
              Chọn thẻ tín dụng VPBank theo nhu cầu chi tiêu cá nhân.
            </div>
          </div>
        </div>
      </div>
      <div className="relative mt-8">
        <img
          src={require("../assets/Rectangle 52.png")}
          alt="Rectangle"
          className="w-full h-auto"
        />
        <div className="absolute top-2 left-0 p-2 flex items-center ">
          <div>
            <img src={require("../assets/logo/ưu đãi.png")} alt="Image 4" className="w-[200px] mt-8"/>
          </div>
          <div className="flex flex-col items-center text-center w-[77%] mt-5">
            <div class="font-extrabold text-white leading-tight mb-1">
              ƯU ĐÃI <span class="text-yellow-500">GIẢM GIÁ</span>
              <br /> ĐỐI TÁC LÊN ĐẾN <span class="text-yellow-500">50%</span>
            </div>
            <div class="text-[11px] text-white font-light">
              Tại hơn 5,000 đối tác cửa hàng ăn uống, du lịch, mua sắm, giải
              trí, học tập
            </div>
            <div class="font-extrabold text-white leading-tight mt-2 mb-1">
              MUA SẮM{" "}
              <span class="text-yellow-500">
                TRƯỚC <br />
              </span>{" "}
              TRẢ GÓP <span class="text-yellow-500">0%</span> SAU
            </div>
            <div class="text-[11px] text-white font-light">
              Chuyển đổi trả góp với kỳ hạn linh hoạt tới 12 tháng chỉ với 4
              bước đơn giản
            </div>
          </div>
        </div>
      </div>

      <div class="flex mt-10 ml-3 mr-3 mb-14">
        <div class="flex flex-col items-center text-center w-[60%] mt-10">
          <div class="font-extrabold text-yellow-500 leading-tight mb-1 ">
            QUÀ TẶNG{" "}
            <span class="text-black">
              MỞ THẺ
              <br /> LÊN ĐẾN
            </span>{" "}
            500.000 VNĐ
          </div>
          <div class="text-[11px] text-black font-light">
            Và nhiều phần quà tặng ưu đãi khác theo từng loại thẻ đang chờ bạn
          </div>
        </div>
        <div>
          <img src={require("../assets/logo/quà tặng mở thẻ.png")} alt="Image 4" className="w-[170px] ml-2 mt-3"/>
        </div>
      </div>
      <div className="relative mt-8 ">
        <img
          src={require("../assets/Rectangle 43.png")}
          alt="Rectangle"
          className="w-full h-auto"
        />
        <div className="absolute top-0 left-0 p-4 flex flex-col space-y-2 ">
          <div className="flex flex-col items-center text-center mt-2 ml-[70px]">
            <div className="font-extrabold  text-white text-xl w-[100%]">
              CÓ THẺ <span className="text-yellow-500">DÙNG NGAY </span>
              <br />
              CHỈ <span className="text-yellow-500">4 BƯỚC </span> ĐƠN GIẢN
            </div>
          </div>

          <div className="text-white ml-3">
            <div className="font-bold text-sm">Bước 1:</div>
            <div className="font-light text-xs">Nhập thông tin đăng ký</div>
          </div>
          <div className="text-white ml-3">
            <div className="font-bold text-sm">Bước 2:</div>
            <div className="font-light text-xs">
              Định danh điện tử với CCCD gắn chip
            </div>
          </div>
          <div className="text-white ml-3">
            <div className="font-bold text-sm">Bước 3:</div>
            <div className="font-light text-xs">
              Lựa chọn thẻ phù hợp theo nhu cầu
            </div>
          </div>
          <div className="text-white ml-3">
            <div className="font-bold text-sm">Bước 4:</div>
            <div className="font-light text-xs">
              Kích hoạt thẻ online sau khi phê duyệt
            </div>
          </div>
        </div>
      </div>

      <div className="relative mt-8 ">
        <img
          src={require("../assets/Rectangle 37.png")}
          alt="Rectangle"
          className="w-full h-auto"
        />
        <div className="absolute top-24 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
          <img
            src={require("../assets/logo/logoambanpng.png")}
            alt="Group"
            className="w-44"
          />
          <p className="text-white text-sm mt-1">Agency banking</p>
        </div>
        <div className="absolute top-[250px] left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="flex">
            <button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/fimi.ltd",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              className="flex bg-[#CE5757E5] pt-2 pb-2 pl-5 pr-5 rounded-xl mr-3 transition-transform duration-200 hover:scale-110"
            >
              <a
                href="#!"
                role="button"
                class="inline-flex items-center justify-center rounded-full bg-white p-1"
              >
                <span class="[&>svg]:h-5 [&>svg]:w-5 [&>svg]:fill-[#ba2222]">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                  </svg>
                </span>
              </a>
              <div className="text-white text-xs mt-[7px] ml-2 tracking-wide">
                Facebook
              </div>
            </button>
            <button
              onClick={() => (window.location.href = "tel:0393017031")}
              className="flex bg-[#CE5757E5]  pt-2 pb-2 pl-4 pr-4 rounded-xl ml-4 transition-transform duration-200 hover:scale-110"
            >
              <a
                href="#!"
                role="button"
                class="inline-flex items-center justify-center rounded-full bg-white p-2"
              >
                <span class="[&>svg]:h-3 [&>svg]:w-3 [&>svg]:fill-[#ba2222]">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
                  </svg>
                </span>
              </a>
              <div className="text-white text-[12px] mt-[7px] ml-1 tracking-wide ">
                0393 017 031
              </div>
            </button>
          </div>
          <div className="flex mt-3">
            <button
              onClick={() =>
                window.open(
                  "https://zalo.me/349258862182110585",
                  "_blank",
                  "noopener,noreferrer"
                )
              }
              className="flex w-[110px] bg-[#CE5757E5] pt-2 pb-2 pl-5 pr-5 rounded-xl mr-4 transition-transform duration-200 hover:scale-110"
            >
   
             <img className="w-7 h-7 " src={zalologo} alt="logo" />
       
              <div className="text-white text-xs mt-[7px] ml-1 tracking-wide ">
                Zalo
              </div>
            </button>

            <button
              onClick={() => (window.location.href = "mailto:admin@fimi.tech")}
              className="flex bg-[#CE5757E5] pt-2 pb-2 pl-5 pr-5 rounded-xl transition-transform duration-200 hover:scale-110"
            >
              <a
                role="button"
                class="inline-flex items-center justify-center rounded-full bg-white p-1"
              >
                <span class="[&>svg]:h-5 [&>svg]:w-5 [&>svg]:text-[#ba2222]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                </span>
              </a>
              <div className="text-white text-xs mt-[7px] ml-2 tracking-wide">
                admin@fimi.tech
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 w-[410px]">
        <button
          onClick={() => navigate("/vpbank-creditcard")}
          className="w-full h-[37px] bg-gradient-to-r from-red-900 to-red-600 text-white rounded-[11px] text-sm font-bold shadow-lg hover:shadow-xl transition-all transform hover:scale-105"
        >
          Mở thẻ ngay
        </button>
      </div>
    </div>
  );
};

export default PageStart;
