import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../UserContext";
import {
  chooseProduct,
  getLead,
  getOfferCard,
  UploadDcument,
} from "../services/apiService";
import LoadingOverlay from "../components/Loading";
const id = localStorage.getItem("id");
const userId = localStorage.getItem("userId");

const ChooseProduct = () => {
  const navigate = useNavigate();
  const { productInfo, setProductInfo } = useUserContext();
  const [showAllCards, setShowAllCards] = useState(false);
  const [offerCards, setOfferCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [loadings, setLoadings] = useState(false);
  const [offerCard, setOfferCard] = useState([]);
  const [limitOffer, setLimitOffer] = useState(0);
  const [kycSegment, setKycSegment] = useState(null);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const finalId = id !== null ? id : userId;
  useEffect(() => {
    const hasReloaded = localStorage.getItem("hasReloaded");
    if (!hasReloaded) {
      localStorage.setItem("hasReloaded", "true");
      window.location.reload();
    } else {
      localStorage.removeItem("hasReloaded");
    }
  
    const fetchData = async () => {
      try {
        setLoadings(true);
        const leadResponse = await getLead(finalId);
        const { offer_card, limit_offer, kyc_segment } = leadResponse.data.data;
        setOfferCard(offer_card);
        setLimitOffer(limit_offer);
        setKycSegment(kyc_segment);
        localStorage.setItem("kyc_segment", kyc_segment);
        const offerCardResponse = await getOfferCard();
        setOfferCards(offerCardResponse.data.data);
  
        const localOfferCards = offer_card ? offer_card.split(",") : [];
        const matchedCards = offerCardResponse.data.data.filter((card) =>
          localOfferCards.includes(card.code)
        );
        setFilteredCards(matchedCards);
        setIsDataFetched(true);
      } catch (error) {
        console.error(error);
      } finally {
        setLoadings(false);
      }
    };
  
    fetchData();
  }, []);
  
  const handleSelectCard = async (szcode) => {
    setLoadings(true);

    const dataToSubmit = {
      card_type: szcode,
    };
    try {
      const response = await chooseProduct(finalId, dataToSubmit);
      if (response.status === 200) {
        setProductInfo(dataToSubmit);
        navigate("/vpbank-creditcard/guide");
      } else {
        console.error("Failed to upload document:", response);
      }
    } catch (error) {
      console.error("Error uploading document:", error);
    } finally {
      setLoadings(false);
    }
  };
  const formatToMillion = (value) => {
    const number = parseInt(value, 10);
    return `${(number / 1_000_000).toLocaleString("vi-VN")} triệu đồng`;
  };

  return (
    <div className="flex flex-col items-center p-5 bg-white h-full">
      {loadings && <LoadingOverlay />}

      <h1 className="bg-gradient-to-r from-green-700 to-green-500 bg-clip-text text-transparent text-2xl font-bold mt-6 text-center">
        Lựa chọn sản phẩm
      </h1>
      <p className="text-sm font-normal mt-3 text-gray-400 text-center">
        Chúc mừng bạn đã đủ điều kiện mở thẻ VPBank
      </p>
      <h2 className="text-base font-medium mt-4 text-black text-center">
        Hạn mức thẻ{" "}
        <span className="text-green-600 text-xl">
          {" "}
          {formatToMillion(limitOffer)}
        </span>
      </h2>

      <div className="mt-8 flex flex-col items-center">
        {!isDataFetched ? (
          <p className="text-gray-500 text-center">Đang tải dữ liệu...</p>
        ) : (
          filteredCards.map((card, index) => (
            <div className="flex flex-col items-center mb-6" key={index}>
              <img src={card.image} alt="image" className="w-64 h-40 mt-5" />
              <h3 className="bg-gradient-to-r from-green-600 to-green-500 bg-clip-text text-transparent text-lg font-bold mt-4 text-center">
                {card.name}
              </h3>
              <div className="flex flex-col w-[77%] mt-2 ml-1">
                <div className="flex">
                  <img
                    src={require("../assets/icon/vp.png")}
                    alt="Icon"
                    className="w-5 h-5 mr-1"
                  />
                  <p className="text-xs font-normal mt-[3px] text-gray-400">
                    {card.features.replace(/[\[\]"]+/g, "").replace(/,/g, ", ")}
                  </p>
                </div>
                <div className="flex">
                  <img
                    src={require("../assets/icon/vp.png")}
                    alt="Icon"
                    className="w-5 h-5 mr-1"
                  />
                  <p className="text-xs font-normal mt-[3px] text-gray-400">
                    {card.description}
                  </p>
                </div>
              </div>
              <div className="items-center justify-center mt-5">
                <button
                  onClick={() => handleSelectCard(card.code)}
                  className="bg-green-500 rounded-lg w-64 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105 hover:bg-green-500"
                >
                  <span className="text-white text-base font-semibold">
                    Chọn thẻ
                  </span>
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      <div className="items-center justify-center">
        <button
          onClick={() => setShowAllCards(!showAllCards)}
          className="bg-white border-2 border-green-500 rounded-xl w-64 h-12 items-center justify-center mb-5 transition-transform duration-300 hover:scale-105 hover:bg-white"
        >
          <span className="text-green-500 text-base font-semibold">
            {showAllCards ? "Ẩn thẻ" : "Xem toàn bộ thẻ"}
          </span>
        </button>
      </div>
    </div>
  );
};

export default ChooseProduct;
