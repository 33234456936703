import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HeaderRegister from "../components/HeaderReigister";
import { useUserContext } from "../UserContext";
import { Basic_info, getPlaceIssues } from "../services/apiService";
import LoadingOverlay from "../components/Loading";
import Select from "react-select";

const Step2Screen = () => {
  const navigate = useNavigate();
  const [currentStep] = useState(2);
  const { userInfo, setUserInfo } = useUserContext();
  const [placeIssues, setPlaceIssues] = useState([]);
  const [documentNumber, setDocumentNumber] = useState("");
  const [documentAddtional, setDocumentAddtional] = useState("");
  const [dateOfIssue, setDateOfIssue] = useState("");
  const [dateOfExpiry, setDateOfExpiry] = useState("");
  const [placeOfIssue, setPlaceOfIssue] = useState("");
  const id = localStorage.getItem("id");
  const [errors, setErrors] = useState({});
  const [loadings, setLoadings] = useState(false);
  const [isUnlimited, setIsUnlimited] = useState(false);
  const isBornBefore1995 = localStorage.getItem("isBornBefore1995") === "true";
  const handleFormSubmit = () => {
    const dataToSubmit = {
      ...userInfo,
      document_number: documentNumber,
      document_additional: documentAddtional,
      date_of_issue: dateOfIssue,
      date_of_expiry:dateOfExpiry,
      place_of_issue: placeOfIssue.value,
    };
    const dataStatus = {
      document_number: documentNumber,
      document_additional: documentAddtional,
      date_of_issue: dateOfIssue,
      date_of_expiry:dateOfExpiry,
      place_of_issue: placeOfIssue.value,
    };

    const newErrors = {};
    if (!documentNumber) {
      newErrors.documentNumber = "Số CCCD không được bỏ trống.";
    } else if (!/^\d{12}$/.test(documentNumber)) {
      newErrors.documentNumber = "Số CCCD phải là 12 chữ số.";
    }

    if (isBornBefore1995) {
      if (!documentAddtional) {
        newErrors.documentAddtional = "Số CMND không được bỏ trống.";
      } else if (!/^\d{9}$/.test(documentAddtional)) {
        newErrors.documentAddtional = "Số CMND phải là 9 chữ số.";
      }
    }
    

    if (!dateOfIssue) {
      newErrors.dateOfIssue = "Ngày phát hành không được bỏ trống.";
    } else {
      const today = new Date().toISOString().split("T")[0];
      if (dateOfIssue > today) {
        newErrors.dateOfIssue = "Ngày phát hành không hợp lệ.";
      }
    }
    if (!placeOfIssue) newErrors.placeOfIssue = "Nơi cấp không được bỏ trống.";
      if (!dateOfExpiry && !isUnlimited) {
    newErrors.dateOfExpiry = "Ngày hết hạn không được bỏ trống.";
  }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setUserInfo(dataToSubmit);
      Basic_info(id, dataStatus)
        .then((response) => {
          if (response.data.success) {
            navigate("/vpbank-creditcard/step3Screen");
          } else {
            console.error("Error saving starting info:", response.data.message);
          }
        })
        .catch((error) => console.error("Error saving starting info:", error));
    }
  };
  useEffect(() => {
    setLoadings(true);
    getPlaceIssues()
      .then((response) => {
        setPlaceIssues(response.data);
      })
      .catch((error) => console.error(error))
      .finally(() => setLoadings(false));
  }, []);

  return (
    <div className="flex flex-col items-center p-4 bg-white ">
      {loadings && <LoadingOverlay />}
      <HeaderRegister currentStep={currentStep} />
      <div className="top-8 p-3 mt-8 bg-[#52df8f] transform rounded-[15px] w-full max-w-md custom-shadow">
        <div className="text-center mb-4 mt-2">
          <p className="text-xl font-bold uppercase">Thông tin cơ bản</p>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Số CCCD:
          </label>
          <input
            className={`h-10 rounded-md text-sm px-2 bg-white w-full ${
              errors.documentNumber ? "border border-red-500" : ""
            }`}
            placeholder="Nhập số CCCD"
            type="tel"
            inputMode="numeric"
            maxLength={12}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              setDocumentNumber(e.target.value);
            }}
          />
          {errors.documentNumber && (
            <p className="text-red-500 text-xs">{errors.documentNumber}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Ngày phát hành:
          </label>
          <input
            className={`h-10 rounded-md text-sm px-2 bg-white w-full ${
              errors.dateOfIssue ? "border border-red-500" : ""
            }`}
            placeholder="Nhập ngày phát hành"
            type="date"
            onChange={(e) => setDateOfIssue(e.target.value)}
          />
          {errors.dateOfIssue && (
            <p className="text-red-500 text-xs">{errors.dateOfIssue}</p>
          )}
        </div>
        <div className="mb-4 flex">
          <div className="w-[70%] mr-3">
            <label className="block text-sm font-semibold ml-2 mb-1">
              Ngày hết hạn:
            </label>
            <input
              className={`h-10 rounded-md text-sm px-2 bg-white w-full ${
                errors.dateOfExpiry ? "border border-red-500" : ""
              }`}
              placeholder="Nhập ngày phát hành"
              type="date"
              disabled={isUnlimited}
              onChange={(e) => setDateOfExpiry(e.target.value)}
            />
                  {errors.dateOfExpiry && (
            <p className="text-red-500 text-xs">{errors.dateOfExpiry}</p>
          )}
          </div>
          
          <div className="flex items-center mt-4 w-[30%]">
            <input
              type="checkbox"
              id="unlimited"
              className="h-4 w-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
              checked={isUnlimited}
              onChange={(e) => {
                setIsUnlimited(e.target.checked);
                if (e.target.checked) {
                  setDateOfExpiry("9999-04-20"); 
                } else {
                  setDateOfExpiry("");
                }
              }}
            />
            <label
              htmlFor="unlimited"
              className="ml-2 text-sm font-medium text-gray-700"
            >
              Vô thời hạn
            </label>
          </div>
    
        </div>

        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Nơi cấp:
          </label>

          <Select
            value={placeOfIssue}
            onChange={setPlaceOfIssue}
            options={placeIssues.map((placeIssue) => ({
              label: placeIssue.name,
              value: placeIssue.code,
            }))}
            placeholder="Chọn nơi cấp"
          />
          {errors.placeOfIssue && (
            <p className="text-red-500 text-xs">{errors.placeOfIssue}</p>
          )}
        </div>
        <div className="mb-4">
          <label className="block text-sm font-semibold ml-2 mb-1">
            Số CMND
            {/* <span className="text-xs font-normal text-gray-500 ml-1">
              (không bắt buộc)
            </span> */}
          </label>

          <input
            className={`h-10 rounded-md text-sm px-2 bg-white w-full ${
              errors.documentAddtional ? "border border-red-500" : ""
            }`}
            placeholder="Nhập số CMND"
            type="tel"
            inputMode="numeric"
            maxLength={9}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
              setDocumentAddtional(e.target.value);
            }}
          />
          {errors.documentAddtional && (
            <p className="text-red-500 text-xs">{errors.documentAddtional}</p>
          )}
        </div>
      </div>
      <div className="items-center justify-center mt-10 mb-14">
        <button
          onClick={handleFormSubmit}
          className="bg-gradient-to-r from-green-700 to-green-500 rounded-[17px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"        >
        
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>
    </div>
  );
};

export default Step2Screen;
