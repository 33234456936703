import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _, { get } from "lodash";
import { useUserContext } from "../UserContext";
import LoadingOverlay from "../components/Loading";
import {
  getUpdateToLOS,
  updateLead,
  UploadDcument,
  uploadEKYCLiveness,
  uploadLiveness,
  uploadOCRBack,
  uploadOCRFront,
} from "../services/apiService";
import Swal from "sweetalert2";

const Face = () => {
  const navigate = useNavigate();
  const [listObjUrls, setListObjUrls] = useState([]);
  const lang = localStorage.getItem("lang");
  const [scanCount, setScanCount] = useState(0);
  const userIdEl = document.getElementById("user-id");
  const trackingConfigEl = document.getElementById("tracking-config");
  const readIdCardLoadingEl = document.getElementById("read-id-card-loading");
  const statusWarmupReadIDCardEl = document.getElementById(
    "status-warmup-read-id-card"
  );
  const livenessLoadingEl = document.getElementById("liveness-loading");
  const startLivenessBtn = document.getElementById("btn-start-liveness");
  const idCardSettingsEl = document.getElementById("id-card-settings");
  const idCardStepsEl = document.getElementById("id-card-steps");
  const { productInfo, setProductInfo } = useUserContext();
  const id = localStorage.getItem("id");
  const userId = localStorage.getItem("userId");
  const [loadings, setLoadings] = useState(false);
  const [errors, setErrors] = useState({});
  const { userInfo } = useUserContext();
  useEffect(() => {
    console.log("Updated productInfo:", productInfo);
  }, [productInfo]);
  const handleSubmit = async (updatedProductInfo) => {
    const newErrors = {};
    setErrors(newErrors);
    if (Object.keys(newErrors).length === 0) {
      setLoadings(true);
      try {
        const finalId = id !== null ? id : userId;
        const dataStatus = {
          Liveness: {
            steps: updatedProductInfo.steps,
            capturedFrames: updatedProductInfo.capturedFrames,
            frontalFaces: updatedProductInfo.frontalFaces,
          },
        };
        await uploadEKYCLiveness(finalId, dataStatus);
        const livenessResponse = await uploadLiveness(finalId);

        switch (
          String(get(livenessResponse, "data.data.status")).toUpperCase()
        ) {
          case "SUCCESS": {
            navigate("/vpbank-creditcard/contact");
            return;
          }
          case "RETRY": {
            Swal.fire({
              icon: "warning",
              title: "Yêu cầu thực hiện lại xác thực gương mặt!",
              text: get(livenessResponse, "data.data.message", ""),
              showCancelButton: true,
              confirmButtonText: "Có",
              cancelButtonText: "Không",
            });
            return;
          }
          case "CANCEL": {
            navigate("/vpbank-creditcard/reject");
            return;
          }
        }
      } catch (error) {
        console.error("Cập nhật thất bại", error);
        Swal.fire({
          icon: "error",
          title: "Lỗi",
          text: ""
        });
      } finally {
        setLoadings(false);
      }
    }
  };
  // const handleStartCard = React.useCallback(
  //    clientSettings => {
  //      refTVWebSDK.current.livenessDetection(
  //       {
  //         mode: TVWebSDK.Constants.Mode.ACTIVE,
  //          onLivenessDetectionDone: handleDone,
  //           onError: handleErrorSdkTS, clientSettings,
  //            onProcessing,
  //             captureFrameSettings,
  //       }
  //     )
  //   }
  // )
  function blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }
  const myCdnUrlPrefix = "https://vision-vnetwork-cdn.goevo.vn/";
  const customUrls = {
    blazefaceModelUrl: `${myCdnUrlPrefix}models/1.0.0/blazeface/model.json`,
    opencvUrl: `${myCdnUrlPrefix}opencv/4.6.0/cv.js`,
    idCardModelUrl: `${myCdnUrlPrefix}models/1.0.0/id_card/tflite/model-v3.tflite`,
    wechatQRModelsUrl: `${myCdnUrlPrefix}models/1.0.0/webchat_qr/`,
    tfScriptUrls: {
      tfUrl: `${myCdnUrlPrefix}tfjs/3.20.0/tf.min.js`,
      tfBackendWasmUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-wasm.js`,
      tfBackendCpuUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-cpu.js`,
      tfBackendWebGlUrl: `${myCdnUrlPrefix}tfjs/3.20.0/backend-webgl.js`,
      tfLiteUrl: `${myCdnUrlPrefix}tflite/0.0.1-alpha.8/tflite.min.js`,
      blazefaceScriptUrl: `${myCdnUrlPrefix}blazeface/0.0.7/bf.js`,
    },
  };
  const sdkVersion = "5.22.11";

  useEffect(() => {
    const tv = new window.TVWebSDK.SDK({
      container: document.getElementById("TakeShot"),
      lang: lang || "vi",
      assetRoot: myCdnUrlPrefix + "tvweb-sdk/" + sdkVersion + "/assets",
      resourceRoot: myCdnUrlPrefix,
      customUrls: customUrls,
      enableAntiDebug: false,
    });
    window.tv = tv;
    tv.runPreloadEKYCResources();

    const userIdEl = document.getElementById("user-id");
    userIdEl.value = JSON.parse(localStorage.getItem("user-id"));

    const selectModeEl = document.getElementById("select-mode");
    Object.keys(window.TVWebSDK.Constants.Mode).forEach((k) => {
      const option = document.createElement("option");
      option.appendChild(document.createTextNode(k));
      option.setAttribute("value", window.TVWebSDK.Constants.Mode[k]);
      selectModeEl.appendChild(option);
    });

    const idCardSettingsEl = document.getElementById("id-card-settings");
    const idCardStepsEl = document.getElementById("id-card-steps");
    const trackingConfigEl = document.getElementById("tracking-config");

    idCardSettingsEl.value = JSON.stringify(
      window.TVWebSDK.defaultClientSettings,
      null,
      2
    );
    idCardStepsEl.value = JSON.stringify(
      window.TVWebSDK.defaultReadIDCardSteps,
      null,
      2
    );
    trackingConfigEl.value = JSON.stringify(
      window.TVWebSDK.TrackingContext.defaultTrackingConfig,
      null,
      2
    );

    return () => {
      tv.destroyView();
    };
  }, [lang]);

  function onSelectLang() {
    const langEle = document.getElementById("select-lang");
    localStorage.setItem("lang", langEle.value);
    window.location.reload();
  }

  function createObjectURL(blob) {
    const objUrl = URL.createObjectURL(blob);
    setListObjUrls((prev) => [...prev, objUrl]);
    return objUrl;
  }

  function revokeObjectURL() {
    listObjUrls.forEach((item) => URL.revokeObjectURL(item));
    setListObjUrls([]);
  }

  function clearUserId() {
    window.localStorage.removeItem("user-id");
    const userIdEl = document.getElementById("user-id");
    userIdEl.value = "";
  }

  function startLivenessDetection() {
    revokeObjectURL();
    const livenessProps = {
      mode: window.TVWebSDK.Constants.Mode.ACTIVE,
      onLivenessDetectionDone: handleLivenessDetectionDone,
      captureFrameSettings: {
        enable: true,
        framesIntervalTime: 180,
        framesBatchLength: 15,
      },
      onError: (e) => {
        console.log(e);
      },
      frontCamera: true,
      onProcessing: () => {
        setTimeout(() => {
          window.tv.destroyView();
        }, 250);
      },
      onClose: () => {
        document.body.style.height = "auto";
        window.tv.destroyView();
      },
    };
    window.tv.livenessDetection(livenessProps);
   
  }

  async function handleLivenessDetectionDone(result) {
    const { frontalFaces, capturedFrames, steps } = result;
    const frontalFacesBase64 = await Promise.all(
      frontalFaces.map(async (blob) => {
        const base64 = await blobToBase64(blob);
        return base64.replace(/^data:image\/[a-zA-Z]+;base64,/, "");
      })
    );
    const stepsWithBase64 = await Promise.all(
      steps.map(async (s) => {
        const base64 = await blobToBase64(s.image.blob);
        return {
          name: s.name,
          image: base64.replace(/^data:image\/[a-zA-Z]+;base64,/, ""),
        };
      })
    );
      const updatedProductInfo = {
      steps: stepsWithBase64,
      capturedFrames: result.capturedFrames.map((frame) => frame.base64),
      frontalFaces: frontalFacesBase64,
    };
    setProductInfo((prevInfo) => ({
      ...prevInfo,
      ...updatedProductInfo,
    }));
    if (!window.inputAccessKey.value) {
      frontalFaces.forEach((blob) => {
        const imgEl = document.createElement("img");
        imgEl.width = "200";
        imgEl.style.marginRight = "5px";
        imgEl.src = createObjectURL(blob);
      });

      steps.forEach((s) => {
        const imgEl = document.createElement("img");
        imgEl.width = "200";
        imgEl.style.marginRight = "5px";
        imgEl.src = createObjectURL(s.image.blob);
      });
    }
    window.tv.destroyView();
    handleSubmit(updatedProductInfo);
  }

  return (
    <div className="flex flex-col items-center p-5 bg-white ">
      <div id="TakeShot"></div>
      {loadings && <LoadingOverlay />}
      <h1 className="text-2xl font-bold mt-2 text-center bg-gradient-to-r from-green-600 to-green-500 bg-clip-text text-transparent">
        Xác thực gương mặt
      </h1>

      <p className="text-sm font-normal mt-3 text-gray-600 text-center">
        Vui lòng chụp hình gương mặt để xác thực.
      </p>
      <div style={{ display: "none" }}>
        <input id="user-id" type="text" placeholder="User ID" />
        <button onClick={clearUserId}>Clear User ID</button>
      </div>
      <div style={{ display: "none" }}>
        <label htmlFor="select-lang">Select Language:</label>
        <select id="select-lang" onChange={onSelectLang}>
          <option value="vi">Tiếng Việt</option>
          <option value="en">English</option>
        </select>
      </div>
      <div style={{ display: "none" }}>
        <label htmlFor="select-mode">Select Mode:</label>
        <select id="select-mode"></select>
      </div>
      <div style={{ display: "none" }}>
        <label htmlFor="select-camera">Select Camera:</label>
        <select id="select-camera">
          <option value="true">Front Camera</option>
          <option value="false">Back Camera</option>
        </select>
      </div>
      <div className="h-40 w-[96%] bg-gray-300 rounded-3xl mt-5 flex flex-col items-center custom-shadow">
        <img
          id="btn-start-liveness"
          src={require("../assets/face.png")}
          alt="Chụp gương mặt"
          className="w-14 h-[80px] mt-4"
        />
        <span className="text-lg mt-4 font-medium text-slate-600">
          Chụp gương mặt
        </span>
      </div>
      <div className="items-center justify-center mt-10 mb-4">
        <button
          onClick={startLivenessDetection}
          className="bg-gradient-to-r from-green-700 to-green-500 rounded-[10px] w-60 h-10 flex items-center justify-center transition-transform duration-300 hover:scale-105"        >
        
          <span className="text-white text-base font-semibold">Tiếp tục</span>
        </button>
      </div>

      <div id="result-liveness-detection" style={{ display: "none" }}></div>
      <div id="result-liveness-detection-error"></div>
      <div id="id-card-settings"></div>
      <div id="id-card-steps"></div>
      <div id="tracking-config"></div>
      <div id="read-id-card-loading"></div>
    </div>
  );
};

export default Face;
