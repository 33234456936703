import React from "react";
import cardImage from "../assets/card.png";
import mailImage from "../assets/mail.png";
import logofimi from "../assets/Logo FiMi 4.svg";
const Complete = () => {
  return (
    <div className="flex flex-col items-center p-4 bg-white">
      <div className="w-full text-left mt-2 ml-10 flex">
        <h1 className="mt-4 text-2xl font-bold  bg-gradient-to-r from-green-800 to-green-600 bg-clip-text text-transparent">
          Hoàn Thành Đăng Ký
        </h1>

        <div className="ml-5 mb-[30px]">
          <img
            id="frontImage"
            src={require("../assets/Group 17.png")}
            alt="image"
            className="w-[100%]"
          />
        </div>
      </div>
      <div className="w-[95%] h-[300px] bg-green-300 mt-8 rounded-2xl mb-28 custom-shadow">
        <p className="text-center text-[#123b21] text-[13px] m-3 italic mt-5">
          Chúc mừng bạn đã đăng ký thẻ VPBank thành công thông qua giới thiệu
          tại
          <img
            className="inline-block w-36 h-16 align-middle ml-[-40px] mt-[-10px]"
            src={logofimi}
            alt="logo"
          />
        </p>

        <div className="flex-row m-3 w-[76%] flex items-start ml-10">
          <img src={cardImage} className="mr-2 w-14 h-12 mt-1" alt="Card" />
          <p className="text-sm flex-wrap  ml-2">
            Thẻ vật lý sẽ được gửi đến{" "}
            <span className="text-green-600">VPBank chi nhánh......</span> trong
            vòng <span className="text-green-600">7 ngày </span>
            làm việc.
          </p>
        </div>
        <div className="flex-row m-3 w-[76%] flex items-start ml-10">
          <img src={mailImage} className="mr-2 w-14 h-12 " alt="Mail" />
          <p className="text-sm flex-wrap ml-2">
            Hợp đồng thẻ sẽ được gửi đến địa chỉ email.
          </p>
        </div>
        <div className="italic text-xs ml-6 mt-9">VPBank xin chân thành cảm ơn!</div>
      </div>
    </div>
  );
};

export default Complete;
